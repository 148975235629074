
@import "functions"; // bootstrap
@import "variables";
@import "svg-inline";
@import "mixins"; // bootstrap

// mixins/functions
@import "functions/em";
@import "functions/vw";
@import "mixins/svg-url";

@import "placeholders/form_components";

// Placeholder to use same styles in very different selectors
%pr-subheader {
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 500;
}

/*  Removing left margin on PDP snippet */
.p-w-r {
    font-family: $font-family-sans-serif;
    margin-left: 0;

    /* Removing top margin on PR review and write snippet */
    .pr-snippet-stars-reco-inline {
        &.pr-snippet-compact {
            .pr-snippet-read-and-write {
                margin-top: 0;
            }
        }
    }
    .pr-snippet-read-and-write {
        span {
            // Bring span baseline into line with links
            border-bottom: 1px solid transparent;
            line-height: 11.7px;
        }
    }

    /* Overriding padding on PR snapshot ul */
    .pr-review-snapshot {
        background-color: transparent;
        &.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block {
            &:first-child {
                @media screen and (min-width: 767px) {
                    padding-left: 20px;
                }
            }
            flex: 1;
        }
        .pr-snippet {
            justify-content: space-evenly;
        }
        .pr-snippet-read-and-write {
            span {
                display: inline-block;
                padding: 0 1rem;
            }
        }

        .pr-review-snapshot-header {
            .pr-headline {
                font-family: $font-family-primary;
                font-size: 24px;
                font-weight: $font-weight-semibold;
            }
        }
        .pr-review-snapshot-block {
            background-color: $white;

            .pr-review-snapshot-histogram {
                .pr-review-snapshot-block-headline {
                    @extend %pr-subheader
                }
            }
            .pr-review-snapshot-block-headline {
                @extend %pr-subheader
            }
        }
        .pr-ratings-histogram {
            padding: 0;

            .pr-histogram-label {
                color: #767676;
            }
            .pr-histogram-stars {
                &::before {
                    background: transparent;
                }
                &:hover {
                    .pr-histogram-label {
                        text-decoration: underline;
                    }
                    .pr-ratings-histogram-barValue {
                        background-color: $primary;
                    }
                }
            }
            .pr-ratings-histogram-barValue {
                background-color: $primary;
            }
        }
    }

    /* Overriding left padding on QA definition list  */
    .pr-qa-display-answer-link-container,
    .pr-qa-display-answer {
        padding-left: 0;
    }

    /* Overriding clear on Review details More Details */
    .pr-rd-review-tag {
        clear: none;
    }

    /* Overriding float on Review tag definition list */
    .pr-rd-review-tag {
        dt {
            float: none;
        }
    }

}

/* Overriding bottom positioning on QA subscript  */
.pr-qa-display-item-details .pr-subscript {
    bottom: auto;
}

/* Setting container width on PR Write page */
#pr-write {
    max-width: 600px;
    margin: 0 auto;
}

/* Setting top margin on PR Snippet and PR Question Container */
#pr-reviewsnippet,
#pr-questionsnippet {
	margin-bottom: 15px;
}

/* In some cases, Power Reviews renders a required checkbox that SG then sets to opacity: 0 */
.pr-submit .pr-control-label input[type="checkbox"] {
	opacity: 1;
}

/* Overriding float on definition list items */
#pr-review-snapshot dl dt,
#pr-review-snapshot dl dd,
.pr-qa-display-questionlist dt,
.pr-qa-display-questionlist dd {
    float: none;
    margin-top: 0;
}


/**
 * PowerReviews, Copyright 2019
 * HEAD | 17ba9c488 | 1/15/2019
 */
/*******************************************/
/** POWER REVIEWS STYLE OVERRIDE TEMPLATE **/
/*******************************************/

/**
 * The following high-level CSS selectors can be utilized
 * to create a custom style theme for all PowerReviews
 * content.
 *
 * NOTE: If you wish to hide PowerReviews content,
 * please contact your PowerReviews Client Success
 * Representative.
 */

 .p-w-r {
     /**
     * TYPOGRAPHY
     * --------------------------------------------------
     * Global Color & Typography Definitions
     */
     &,
     span,
     p,
     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
     }
     :focus, a:focus {
        box-shadow: none;
     }

     /**
     * FORM LABELS
     * --------------------------------------------------
     * .pr-control-label > span // Selector that targets all field labels.
     */
     .pr-control-label > span {
     }

     /**
      * BUTTON GROUPS
      * --------------------------------------------------
      * .pr-btn // Selector that targets all buttons.
      * .btn-toggle .pr-btn-default // Selector that targets all unselected toggle buttons.
      * .btn-toggle .pr-btn-default.active // Selector that targets the selected toggle button.
      * .pr-btn-add-tag-submit // Selector that targets the "Add" button that appears aside the custom tag text input field.
      * .pr-btn-fileinput // Selector that targets the file input button.
      * .pr-btn-fileinput span // Selector that targets the file input button text.
      * .pr-btn-delete // Selector that targets the image preview delete button.
      */
     .pr-btn {
     }
     .pr-btn-add-tag-submit {
     }
     .pr-btn-fileinput {
     }
     .pr-btn-fileinput span {
     }
     .pr-btn-delete {
     }

     /**
     * STARS
     * Rating Stars can be customized by overriding the
     * background-image property of the 5 Star-related
     * CSS classes
     * --------------------------------------------------
     * .pr-star-v4-0-filled    // Custom star image - filled 0%
     * .pr-star-v4-25-filled   // Custom star image - filled 25%
     * .pr-star-v4-50-filled   // Custom star image - filled 50%
     * .pr-star-v4-75-filled   // Custom star image - filled 75%
     * .pr-star-v4-100-filled  // Custom star image - filled 100%
     */

    .pr-rating-stars {
        display: inline-flex;

        .pr-star-v4 {
            @include background-svg($svg-star-off);
            border-radius: 0 !important;
            height: 16px !important;
            margin-right: -1px !important;
            margin-left: 0 !important;
            padding: 0 !important;
            position: relative;
            width: 16px !important;

            &::before {
                @include background-svg($svg-star-on);
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                background-size: cover;
            }

        }

         .pr-star-v4-25-filled {
             &::before {
                 width: 35%;
             }
         }

         .pr-star-v4-50-filled {
            &::before {
                width: 50%;
            }
         }

         .pr-star-v4-75-filled {
            &::before {
                width: 65%;
            }
         }

         .pr-star-v4-100-filled {
            &::before {
                width: 100%;
            }
         }


    }
    #pr-review-snapshot {
        &.pr-snapshot-mobile .pr-review-snapshot-block-container {
            .pr-snippet {
                padding: 10px;
            }

            .pr-snippet-stars-container {
                top: 15px;
                left: 6vw;
                width: 92vw;

                .pr-star-v4 {
                    width: 10vw !important;
                    height: 10vw !important;
                }
            }
            .pr-snippet-review-count {
                font-size: 7vw;
                line-height: 9vw;
                margin-right: 5vw;
            }
            &.pr-review-snapshot-simple .pr-review-snapshot-snippets-headline {
                display: block;
                padding-top: 10px;
            }
            .pr-snippet-rating-decimal {
                display: none;
            }
        }
        .pr-review-snapshot-block-container {
            .pr-snippet {
                display: block;
                position: relative;
            }
            .pr-rating-stars, .pr-snippet-stars-png {
                width: 100%;
            }
            .pr-snippet-stars-container {
                position: absolute;
                top: -7px;
                left: 0;
                width: calc(100% - 22px);
                text-align: left;
                .pr-star-v4 {
                    width: 24px !important;
                    height: 24px !important;
                }
            }
            .pr-snippet-review-count {
                text-align: right;
                padding: 0;
            }
            .pr-snippet-write-review-link {
                width: 100%;
            }
        }
        .pr-review-snapshot-recomend {
            .pr-reco-value {
                margin-left: 1rem;
            }
            .pr-snippet-reco-to-friend-yellow, .pr-reco-yellow span, .pr-snippet-reco-to-friend-red, .pr-reco-red span, .pr-snippet-reco-to-friend-orange, .pr-reco-orange span {
                color: #333 !important;
                background-color: unset;
            }
        }
    }

    .pr-snippet {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span, div {
            vertical-align: baseline;
            line-height: 1.1;
        }
    }
    .pr-snippet-stars {
        background-color: transparent;
        &:hover {
            cursor: pointer;
        }
    }
    .pr-snippet-stars-reco-inline, .pr-snippet {
        .pr-snippet-stars-png .pr-rating-stars {
            vertical-align: -0.15rem;
        }
    }
    .pr-category-snippet__rating {
        font-size: 13px;
        .pr-snippet-stars {
            .pr-snippet-rating-decimal {
                padding: 0 0.3rem;
                color: $greyish;
                line-height: 1.7;
            }
        }
    }
    .pr-no-reviews {
        .pr-snippet-rating-decimal {
            display: none;
        }
    }
    .pr-review-snippet-container .pr-snippet-stars-container {
        font-size: 13px; padding-top: 0.1rem;
    }
    .pr-snippet-stars-reco-inline {
        &.pr-snippet-minimal {
            .pr-snippet-read-and-write {
                margin-top: 0;
                a.pr-snippet-review-count {
                    padding: 0 7.5px;
                }
            }
        }

        .pr-snippet-read-and-write {
            align-items: center;
            display: flex;
            font-size: 13px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #757575;
            margin-top: 0;

            .pr-snippet-review-count,
            .pr-snippet-write-review-link {
                color: inherit !important;
                font-size: inherit !important;
            }

            .pr-snippet-review-count {
                padding: 0 7.5px;
                line-height: normal;
                border-bottom: none;
                margin-right: 0 !important;
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .pr-snippet-write-review-link {
                padding: 0;
            }
        }
    }

    .pr-snippet-rating-decimal {
        margin-left: 0.5rem;
        border: none;
    }

    .pr-category-snippet__total {
        color: $greyish;
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        padding-left: 9px;
        &:hover {
            // text-decoration: underline;
            cursor: pointer;
        }
    }
    @media screen and (max-width: 767px) {
        .pr-category-snippet__rating .pr-snippet-stars .pr-snippet-rating-decimal, .pr-category-snippet__total {
            padding-left: 4px;
            margin-left: 0;
            font-size: 12px;
            line-height: 1.5;
        }
    }

    .pr-review-display {
        background-color: transparent;

        .pr-rd-main-header-with-filters {
            .pr-rd-review-header-contents,
            .pr-rd-review-header-sorts {
                padding-left: 0;
                padding-right: 0;
            }
            .pr-rd-review-header-contents {
                background-color: transparent;
            }
            .pr-rd-review-total {
                @extend %pr-subheader;
            }
            /*
            .pr-rd-search-container {
                .pr-rd-search-reviews-input {
                    .pr-rd-search-reviews-icon-button {
                        background-color: $primary-alt;
                        border-color: $primary-alt;
                    }
                }
            }*/
            .pr-rd-sort {
                @extend %input-select;
                @extend %input__not-qty;
            }
        }
        &.pr-rd-display-desktop,
        &.pr-rd-display-tablet  {
            /*.pr-rd-main-header-with-filters {
                .pr-rd-search-container {
                    .pr-rd-search-reviews-input {
                        input {
                            border-color: $primary-alt;
                        }
                    }
                }
            }*/
            .pr-helpful-btn {
                margin-right: 10px;
                &:hover {
                    background-color: $blue;
                    border-color: $blue;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .pr-rd-footer {
            background-color: transparent;
        }
    }

     /**
     * TEXT INPUTS
     * --------------------------------------------------
     * .pr-textinput  // Text Input Styles
     * .pr-textinput:focus // Focused Text Input Styles
     */
     .pr-textinput {
     }
     .pr-textinput:focus {
     }

     /**
     * TEXT AREAS
     * --------------------------------------------------
     * input[type="text"] // Text Area Styles
     * input[type="text"]:focus // Focused Text Area Styles
     */
     .pr-textarea {
     }
     .pr-textarea:focus {
     }

     /**
     * CHECKBOX/RADIO INPUT OPTION LABELS
     * --------------------------------------------------
     * .tag-label // Checkbox Radio Input Label Styles
     */
     .pr-tag-label {
     }

     /**
     * ERROR STATES
     * --------------------------------------------------
     * .pr-has-error .control-label span // Form Field Label Error State Styles
     * .pr-has-error .form-control // Text Input & Text Area Error State Styles
     * .pr-has-error input[type="text"]:focus // Focused Text Input & Text Area Error State Styles
     */
     .pr-has-error .pr-control-label span {
     }
     .pr-has-error .form-control {
     }
     .pr-has-error .pr-textinput:focus,
     .pr-has-error .pr-textarea:focus {
     }

     /**
     * MOBILE STYLES
     * --------------------------------------------------
     */
     @media screen and (max-width: 767px) {
         /**
         * CHECKBOX/RADIO INPUT OPTION LABELS
         * --------------------------------------------------
         * .checkbox label, .radio label // Unselected Styles
         * .checkbox label.checked, .radio label.checked // Selected Styles
         */
         .checkbox label,
         .radio label {
         }
         .checkbox label.checked,
         .radio label.checked {
         }
         .checkbox label.checked span,
         .radio label.checked span {
         }
         /**
         * TAG GROUP 'ADD ITEM' BUTTON
         * --------------------------------------------------
         */
         .pr-btn-add-tag a {
         }
     }

    .pr-helpful-btn {
    @include button-variant($secondary, $secondary, $bright-red, $bright-red);

    span {
        color: $white;
    }

    .pr-thumbs-fill,
    .pr-thumbs-cuff-fill {
        fill: $white;
    }

    &:hover {
        span {
            color: $white;
        }

            .pr-thumbs-fill,
            .pr-thumbs-cuff-fill {
                fill: $white;
            }
        }
    }
    .pr-war {
        .pr-star-v4 {
            width: 24px !important;
            height: 24px !important;
        }
        .pr-header-product-img {
            display: none;
        }
        .pr-table-cell {
            padding-left: 0;
        }
    }
    .thank-you-page  {
        .pr-rating-date {
        border: none;
        }
        .pr-rating-number {
        border: none;
        font-weight: $font-weight-bold;
        }
    }
    .pr-snippet-stars-reco-recos, .pr-snippet-stars-reco-reco {
        display: none;
    }
}

.pr-category-snippet {
    margin-top: auto;
    min-height: 17.5px;
}

#pr-reviewimagedisplay {
    &[data-pr-mounted="true"] .p-w-r >  *:first-child::before {
        content: "Customer submitted images or\00a0video";
        font-family: $font-family-primary;
        font-size: 24px;
        font-weight: $font-weight-semibold;
        display: block;
        border-bottom: 1px solid #ccc;
        margin: 10px 10px 20px 10px;
    }
    &[lang="fr"] .p-w-r > *:first-child::before, &[lang="fr-CA"] .p-w-r > *:first-child::before {
        content: "Images ou vidéos soumises par\00a0le\00a0" "client"; // splitting the content attribute into two strings here is necessary to prevent the hex parser from interpreting the nbsp \00a0 as \00a0c
    }
}

#pr-review-snapshot, #pr-reviewsnapshot {
    .pr-review-snapshot-snippets-headline::before {
        content: "Overall Rating";
        display: block;
        font-family: $font-family-primary;
        font-size: 24px;
        font-weight: $font-weight-semibold;
    }
    &[lang="fr"], &[lang="fr-CA"] {
        .pr-review-snapshot-snippets-headline::before {
            content: "Note Globale";
        }
    }
}
