@charset "UTF-8";
.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='16' height='16' viewBox='0, 0, 16, 16'%3E%3Cpath d='M12.75,5.2 L8,9.95 L3.25,5.2' fill-opacity='0' stroke='%23666666' stroke-width='1.25'/%3E%3C/svg%3E");
  appearance: none;
  background-color: #fff;
  border-radius: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  max-width: 427px;
  padding: 8px 12px 7px;
  width: 100%; }
  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort:active, .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort:focus {
    font-size: 16px; }
  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort::-ms-expand {
    display: none; }
  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .error.pr-rd-sort {
    border-color: #ff001f;
    color: #ff001f; }
  @media (min-width: 1024px) {
    .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
      font-size: 14px; }
      .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort:active, .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort:focus {
        font-size: 14px; } }

.p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  padding-right: 36px; }

.p-w-r .pr-review-snapshot .pr-review-snapshot-block .pr-review-snapshot-histogram .pr-review-snapshot-block-headline, .p-w-r .pr-review-snapshot .pr-review-snapshot-block .pr-review-snapshot-block-headline, .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-total {
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 500; }

/*  Removing left margin on PDP snippet */
.p-w-r {
  font-family: "Nunito Sans", sans-serif;
  margin-left: 0;
  /* Removing top margin on PR review and write snippet */
  /* Overriding padding on PR snapshot ul */
  /* Overriding left padding on QA definition list  */
  /* Overriding clear on Review details More Details */
  /* Overriding float on Review tag definition list */ }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write {
    margin-top: 0; }
  .p-w-r .pr-snippet-read-and-write span {
    border-bottom: 1px solid transparent;
    line-height: 11.7px; }
  .p-w-r .pr-review-snapshot {
    background-color: transparent; }
    .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block {
      flex: 1; }
      @media screen and (min-width: 767px) {
        .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block:first-child {
          padding-left: 20px; } }
    .p-w-r .pr-review-snapshot .pr-snippet {
      justify-content: space-evenly; }
    .p-w-r .pr-review-snapshot .pr-snippet-read-and-write span {
      display: inline-block;
      padding: 0 1rem; }
    .p-w-r .pr-review-snapshot .pr-review-snapshot-header .pr-headline {
      font-family: "Nunito Sans", sans-serif;
      font-size: 24px;
      font-weight: 600; }
    .p-w-r .pr-review-snapshot .pr-review-snapshot-block {
      background-color: #fff; }
    .p-w-r .pr-review-snapshot .pr-ratings-histogram {
      padding: 0; }
      .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-label {
        color: #767676; }
      .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-stars::before {
        background: transparent; }
      .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-stars:hover .pr-histogram-label {
        text-decoration: underline; }
      .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-stars:hover .pr-ratings-histogram-barValue {
        background-color: #007ac3; }
      .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-ratings-histogram-barValue {
        background-color: #007ac3; }
  .p-w-r .pr-qa-display-answer-link-container,
  .p-w-r .pr-qa-display-answer {
    padding-left: 0; }
  .p-w-r .pr-rd-review-tag {
    clear: none; }
  .p-w-r .pr-rd-review-tag dt {
    float: none; }

/* Overriding bottom positioning on QA subscript  */
.pr-qa-display-item-details .pr-subscript {
  bottom: auto; }

/* Setting container width on PR Write page */
#pr-write {
  max-width: 600px;
  margin: 0 auto; }

/* Setting top margin on PR Snippet and PR Question Container */
#pr-reviewsnippet,
#pr-questionsnippet {
  margin-bottom: 15px; }

/* In some cases, Power Reviews renders a required checkbox that SG then sets to opacity: 0 */
.pr-submit .pr-control-label input[type="checkbox"] {
  opacity: 1; }

/* Overriding float on definition list items */
#pr-review-snapshot dl dt,
#pr-review-snapshot dl dd,
.pr-qa-display-questionlist dt,
.pr-qa-display-questionlist dd {
  float: none;
  margin-top: 0; }

/**
 * PowerReviews, Copyright 2019
 * HEAD | 17ba9c488 | 1/15/2019
 */
/*******************************************/
/** POWER REVIEWS STYLE OVERRIDE TEMPLATE **/
/*******************************************/
/**
 * The following high-level CSS selectors can be utilized
 * to create a custom style theme for all PowerReviews
 * content.
 *
 * NOTE: If you wish to hide PowerReviews content,
 * please contact your PowerReviews Client Success
 * Representative.
 */
.p-w-r {
  /**
     * TYPOGRAPHY
     * --------------------------------------------------
     * Global Color & Typography Definitions
     */
  /**
     * FORM LABELS
     * --------------------------------------------------
     * .pr-control-label > span // Selector that targets all field labels.
     */
  /**
      * BUTTON GROUPS
      * --------------------------------------------------
      * .pr-btn // Selector that targets all buttons.
      * .btn-toggle .pr-btn-default // Selector that targets all unselected toggle buttons.
      * .btn-toggle .pr-btn-default.active // Selector that targets the selected toggle button.
      * .pr-btn-add-tag-submit // Selector that targets the "Add" button that appears aside the custom tag text input field.
      * .pr-btn-fileinput // Selector that targets the file input button.
      * .pr-btn-fileinput span // Selector that targets the file input button text.
      * .pr-btn-delete // Selector that targets the image preview delete button.
      */
  /**
     * STARS
     * Rating Stars can be customized by overriding the
     * background-image property of the 5 Star-related
     * CSS classes
     * --------------------------------------------------
     * .pr-star-v4-0-filled    // Custom star image - filled 0%
     * .pr-star-v4-25-filled   // Custom star image - filled 25%
     * .pr-star-v4-50-filled   // Custom star image - filled 50%
     * .pr-star-v4-75-filled   // Custom star image - filled 75%
     * .pr-star-v4-100-filled  // Custom star image - filled 100%
     */
  /**
     * TEXT INPUTS
     * --------------------------------------------------
     * .pr-textinput  // Text Input Styles
     * .pr-textinput:focus // Focused Text Input Styles
     */
  /**
     * TEXT AREAS
     * --------------------------------------------------
     * input[type="text"] // Text Area Styles
     * input[type="text"]:focus // Focused Text Area Styles
     */
  /**
     * CHECKBOX/RADIO INPUT OPTION LABELS
     * --------------------------------------------------
     * .tag-label // Checkbox Radio Input Label Styles
     */
  /**
     * ERROR STATES
     * --------------------------------------------------
     * .pr-has-error .control-label span // Form Field Label Error State Styles
     * .pr-has-error .form-control // Text Input & Text Area Error State Styles
     * .pr-has-error input[type="text"]:focus // Focused Text Input & Text Area Error State Styles
     */
  /**
     * MOBILE STYLES
     * --------------------------------------------------
     */ }
  .p-w-r :focus, .p-w-r a:focus {
    box-shadow: none; }
  .p-w-r .pr-rating-stars {
    display: inline-flex; }
    .p-w-r .pr-rating-stars .pr-star-v4 {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='16' height='16' viewBox='0, 0, 16, 16'%3E%3Cpath d='M14.209,6.402 L10.664,9.595 L11.66,14.283 L7.5,11.91 L3.34,14.283 L4.336,9.595 L0.79,6.402 L5.536,5.904 L7.5,1.509 L9.463,5.904 z' fill='%23E1E1E1'/%3E%3C/svg%3E");
      border-radius: 0 !important;
      height: 16px !important;
      margin-right: -1px !important;
      margin-left: 0 !important;
      padding: 0 !important;
      position: relative;
      width: 16px !important; }
      .p-w-r .pr-rating-stars .pr-star-v4::before {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='16' height='16' viewBox='0, 0, 16, 16'%3E%3Cpath d='M14.209,6.402 L10.664,9.595 L11.66,14.283 L7.5,11.91 L3.34,14.283 L4.336,9.595 L0.79,6.402 L5.536,5.904 L7.5,1.509 L9.463,5.904 z' fill='%23fcc726'/%3E%3C/svg%3E");
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        background-size: cover; }
    .p-w-r .pr-rating-stars .pr-star-v4-25-filled::before {
      width: 35%; }
    .p-w-r .pr-rating-stars .pr-star-v4-50-filled::before {
      width: 50%; }
    .p-w-r .pr-rating-stars .pr-star-v4-75-filled::before {
      width: 65%; }
    .p-w-r .pr-rating-stars .pr-star-v4-100-filled::before {
      width: 100%; }
  .p-w-r #pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-snippet {
    padding: 10px; }
  .p-w-r #pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-snippet-stars-container {
    top: 15px;
    left: 6vw;
    width: 92vw; }
    .p-w-r #pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-snippet-stars-container .pr-star-v4 {
      width: 10vw !important;
      height: 10vw !important; }
  .p-w-r #pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-snippet-review-count {
    font-size: 7vw;
    line-height: 9vw;
    margin-right: 5vw; }
  .p-w-r #pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-block-container.pr-review-snapshot-simple .pr-review-snapshot-snippets-headline {
    display: block;
    padding-top: 10px; }
  .p-w-r #pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-snippet-rating-decimal {
    display: none; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-snippet {
    display: block;
    position: relative; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-rating-stars, .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-snippet-stars-png {
    width: 100%; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-snippet-stars-container {
    position: absolute;
    top: -7px;
    left: 0;
    width: calc(100% - 22px);
    text-align: left; }
    .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-snippet-stars-container .pr-star-v4 {
      width: 24px !important;
      height: 24px !important; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-snippet-review-count {
    text-align: right;
    padding: 0; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-block-container .pr-snippet-write-review-link {
    width: 100%; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-reco-value {
    margin-left: 1rem; }
  .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-snippet-reco-to-friend-yellow, .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-reco-yellow span, .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-snippet-reco-to-friend-red, .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-reco-red span, .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-snippet-reco-to-friend-orange, .p-w-r #pr-review-snapshot .pr-review-snapshot-recomend .pr-reco-orange span {
    color: #333 !important;
    background-color: unset; }
  .p-w-r .pr-snippet {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .p-w-r .pr-snippet span, .p-w-r .pr-snippet div {
      vertical-align: baseline;
      line-height: 1.1; }
  .p-w-r .pr-snippet-stars {
    background-color: transparent; }
    .p-w-r .pr-snippet-stars:hover {
      cursor: pointer; }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-png .pr-rating-stars, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
    vertical-align: -0.15rem; }
  .p-w-r .pr-category-snippet__rating {
    font-size: 13px; }
    .p-w-r .pr-category-snippet__rating .pr-snippet-stars .pr-snippet-rating-decimal {
      padding: 0 0.3rem;
      color: #a6a6a6;
      line-height: 1.7; }
  .p-w-r .pr-no-reviews .pr-snippet-rating-decimal {
    display: none; }
  .p-w-r .pr-review-snippet-container .pr-snippet-stars-container {
    font-size: 13px;
    padding-top: 0.1rem; }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write {
    margin-top: 0; }
    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write a.pr-snippet-review-count {
      padding: 0 7.5px; }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write {
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
    margin-top: 0; }
    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write .pr-snippet-review-count,
    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write .pr-snippet-write-review-link {
      color: inherit !important;
      font-size: inherit !important; }
    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write .pr-snippet-review-count {
      padding: 0 7.5px;
      line-height: normal;
      border-bottom: none;
      margin-right: 0 !important; }
      .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write .pr-snippet-review-count:hover {
        text-decoration: underline;
        cursor: pointer; }
    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write .pr-snippet-write-review-link {
      padding: 0; }
  .p-w-r .pr-snippet-rating-decimal {
    margin-left: 0.5rem;
    border: none; }
  .p-w-r .pr-category-snippet__total {
    color: #a6a6a6;
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 9px; }
    .p-w-r .pr-category-snippet__total:hover {
      cursor: pointer; }
  @media screen and (max-width: 767px) {
    .p-w-r .pr-category-snippet__rating .pr-snippet-stars .pr-snippet-rating-decimal, .p-w-r .pr-category-snippet__total {
      padding-left: 4px;
      margin-left: 0;
      font-size: 12px;
      line-height: 1.5; } }
  .p-w-r .pr-review-display {
    background-color: transparent; }
    .p-w-r .pr-review-display .pr-rd-main-header-with-filters {
      /*
            .pr-rd-search-container {
                .pr-rd-search-reviews-input {
                    .pr-rd-search-reviews-icon-button {
                        background-color: $primary-alt;
                        border-color: $primary-alt;
                    }
                }
            }*/ }
      .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents,
      .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-sorts {
        padding-left: 0;
        padding-right: 0; }
      .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
        background-color: transparent; }
    .p-w-r .pr-review-display.pr-rd-display-desktop, .p-w-r .pr-review-display.pr-rd-display-tablet {
      /*.pr-rd-main-header-with-filters {
                .pr-rd-search-container {
                    .pr-rd-search-reviews-input {
                        input {
                            border-color: $primary-alt;
                        }
                    }
                }
            }*/ }
      .p-w-r .pr-review-display.pr-rd-display-desktop .pr-helpful-btn, .p-w-r .pr-review-display.pr-rd-display-tablet .pr-helpful-btn {
        margin-right: 10px; }
        .p-w-r .pr-review-display.pr-rd-display-desktop .pr-helpful-btn:hover, .p-w-r .pr-review-display.pr-rd-display-tablet .pr-helpful-btn:hover {
          background-color: #007ac3;
          border-color: #007ac3; }
        .p-w-r .pr-review-display.pr-rd-display-desktop .pr-helpful-btn:last-child, .p-w-r .pr-review-display.pr-rd-display-tablet .pr-helpful-btn:last-child {
          margin-right: 0; }
    .p-w-r .pr-review-display .pr-rd-footer {
      background-color: transparent; }
  @media screen and (max-width: 767px) {
    .p-w-r {
      /**
         * CHECKBOX/RADIO INPUT OPTION LABELS
         * --------------------------------------------------
         * .checkbox label, .radio label // Unselected Styles
         * .checkbox label.checked, .radio label.checked // Selected Styles
         */
      /**
         * TAG GROUP 'ADD ITEM' BUTTON
         * --------------------------------------------------
         */ } }
  .p-w-r .pr-helpful-btn {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .p-w-r .pr-helpful-btn:hover {
      color: #fff;
      background-color: #ed001c;
      border-color: #ed001c; }
    .p-w-r .pr-helpful-btn:focus, .p-w-r .pr-helpful-btn.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
    .p-w-r .pr-helpful-btn.disabled, .p-w-r .pr-helpful-btn:disabled {
      color: #fff;
      background-color: #000;
      border-color: #000; }
    .p-w-r .pr-helpful-btn:not(:disabled):not(.disabled):active, .p-w-r .pr-helpful-btn:not(:disabled):not(.disabled).active,
    .show > .p-w-r .pr-helpful-btn.dropdown-toggle {
      color: #fff;
      background-color: black;
      border-color: black; }
      .p-w-r .pr-helpful-btn:not(:disabled):not(.disabled):active:focus, .p-w-r .pr-helpful-btn:not(:disabled):not(.disabled).active:focus,
      .show > .p-w-r .pr-helpful-btn.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
    .p-w-r .pr-helpful-btn span {
      color: #fff; }
    .p-w-r .pr-helpful-btn .pr-thumbs-fill,
    .p-w-r .pr-helpful-btn .pr-thumbs-cuff-fill {
      fill: #fff; }
    .p-w-r .pr-helpful-btn:hover span {
      color: #fff; }
    .p-w-r .pr-helpful-btn:hover .pr-thumbs-fill,
    .p-w-r .pr-helpful-btn:hover .pr-thumbs-cuff-fill {
      fill: #fff; }
  .p-w-r .pr-war .pr-star-v4 {
    width: 24px !important;
    height: 24px !important; }
  .p-w-r .pr-war .pr-header-product-img {
    display: none; }
  .p-w-r .pr-war .pr-table-cell {
    padding-left: 0; }
  .p-w-r .thank-you-page .pr-rating-date {
    border: none; }
  .p-w-r .thank-you-page .pr-rating-number {
    border: none;
    font-weight: 700; }
  .p-w-r .pr-snippet-stars-reco-recos, .p-w-r .pr-snippet-stars-reco-reco {
    display: none; }

.pr-category-snippet {
  margin-top: auto;
  min-height: 17.5px; }

#pr-reviewimagedisplay[data-pr-mounted="true"] .p-w-r > *:first-child::before {
  content: "Customer submitted images or\00a0video";
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  display: block;
  border-bottom: 1px solid #ccc;
  margin: 10px 10px 20px 10px; }

#pr-reviewimagedisplay[lang="fr"] .p-w-r > *:first-child::before, #pr-reviewimagedisplay[lang="fr-CA"] .p-w-r > *:first-child::before {
  content: "Images ou vidéos soumises par\00a0le\00a0" "client"; }

#pr-review-snapshot .pr-review-snapshot-snippets-headline::before, #pr-reviewsnapshot .pr-review-snapshot-snippets-headline::before {
  content: "Overall Rating";
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 600; }

#pr-review-snapshot[lang="fr"] .pr-review-snapshot-snippets-headline::before, #pr-review-snapshot[lang="fr-CA"] .pr-review-snapshot-snippets-headline::before, #pr-reviewsnapshot[lang="fr"] .pr-review-snapshot-snippets-headline::before, #pr-reviewsnapshot[lang="fr-CA"] .pr-review-snapshot-snippets-headline::before {
  content: "Note Globale"; }
