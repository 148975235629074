// Make form components accessible without needing to pull in the entire stylesheet

$input-max-width: 427px;

%input {
    border: 1px solid $input-border-color;
    color: $input-color;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: .4px;
    line-height: normal;
    max-width: $input-max-width;
    padding: 7px 10px 7px 12px;
    text-indent: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
        font-size: 15px;
        padding: 7px 10px 7px 12px;
    }

    &::placeholder {
        color: $input-placeholder-color;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
    }

}

%input-select {
    @include background-svg($svg-dropdown-arrow);

    appearance: none;
    background-color: #fff; // set background color to make height work
    border-radius: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    max-width: $input-max-width;
    padding: 8px 12px 7px;
    width: 100%;
    &:active,
    &:focus {
        font-size: 16px;
    }
    &::-ms-expand {
        display: none;
    }

    &.error {
        border-color: $danger;
        color: $danger;
    }

    @include media-breakpoint-up(lg) {
        font-size: 14px;

        &:active,
        &:focus {
            font-size: 14px;
        }
    }
}

%input__not-qty {
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    padding-right: 36px;
}
